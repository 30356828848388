import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'

declare global {
  interface Window {
    [key: string]: unknown
  }
}

type SectionRawHTMLProp = {
  rawHtml?: {
    id: string
    rawHtml: string
  }
  index: number
}

declare global {
  interface Window {
    whenAvailable: (variableName: string, callback: () => void) => void
  }
}

const SectionRawHTML = ({ rawHtml }: SectionRawHTMLProp) => {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // We're adding this function so that user-defined javascript can wait for libraries to be available
    // eg.
    //   <script src="my-custom-lib.js" />
    //   <script>
    //      window.whenAvailable("myCustomVar", function() {
    //        myCustomVar.doStuff();
    //      });
    //   </script>
    window.whenAvailable = (variableName: string, callback: () => void) => {
      if (window[variableName] !== undefined) {
        callback()
      } else {
        setTimeout(() => window.whenAvailable(variableName, callback), 20)
      }
    }

    // Note: dangerouslySetInnerHtml doesn't run <script> tags.  This way does.
    if (divRef.current && rawHtml) {
      const fragment = document
        .createRange()
        .createContextualFragment(rawHtml.rawHtml)
      divRef.current.append(fragment)
    }
  }, [divRef.current])

  return (
    <div className="edge-container">
      <div ref={divRef} />
    </div>
  )
}

export default SectionRawHTML

export const query = graphql`
  fragment ContentfulSectionRawHtmlFields on ContentfulSectionRawHtml {
    id
    rawHtml {
      id
      rawHtml
    }
  }
`
