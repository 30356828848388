import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import DirectoryBanner from 'components/directory-banner'
import SectionText, { SectionTextVariant } from 'components/section-text'
import SectionTextBoxes from 'components/section-text-boxes'
import SectionPartnerLinks from 'components/section-partner-links'
import SectionRawHTML from 'components/section-raw-html'
import {
  ContentfulDirectorySection,
  ContentfulPageDirectory,
} from 'types/contentful'

type DataProps = {
  contentfulPageDirectory: ContentfulPageDirectory
}

const DirectoryPage: React.FC<PageProps<DataProps>> = (props) => {
  const { layout, header, seo, sections } = props.data.contentfulPageDirectory

  return (
    <Layout layout={layout} header={header} seo={seo}>
      <DirectoryBanner
        contentfulPageDirectory={props.data.contentfulPageDirectory}
      />
      {(sections || []).map((section, index) => {
        return (
          <div id={`section-${index + 1}`} key={section.id}>
            {renderSection(section, index)}
          </div>
        )
      })}
    </Layout>
  )
}

const renderSection = (section: ContentfulDirectorySection, index: number) => {
  switch (section.__typename) {
    case 'ContentfulSectionText':
      return (
        <div className="edge-container">
          <SectionText
            index={index}
            variant={SectionTextVariant.Secondary}
            {...section}
          />
        </div>
      )
    case 'ContentfulSectionTextBoxes':
      return <SectionTextBoxes index={index} {...section} />
    case 'ContentfulSectionPartnerLinks':
      return <SectionPartnerLinks index={index} {...section} />
    case 'ContentfulSectionRawHtml':
      return <SectionRawHTML index={index} {...section} />
    default:
      return null
  }
}

export default DirectoryPage

export const pageQuery = graphql`
  query DirectoryQuery($contentful_id: String!) {
    contentfulPageDirectory(contentful_id: { eq: $contentful_id }) {
      contentful_id
      slug
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      seo {
        ...SeoFields
      }
      ...DirectoryBannerFields
      sections {
        __typename
        ... on ContentfulSectionText {
          ...ContentfulSectionTextFields
        }
        ... on ContentfulSectionTextBoxes {
          ...ContentfulSectionTextBoxesFields
        }
        ... on ContentfulSectionPartnerLinks {
          ...ContentfulSectionPartnerLinksFields
        }
        ... on ContentfulSectionRawHtml {
          ...ContentfulSectionRawHtmlFields
        }
      }
    }
  }
`
