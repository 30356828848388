import React from 'react'
import { ContentfulLayout } from 'types/contentful'
import { useLayoutContext } from './layout'
import * as styles from './button.module.css'

type ButtonVariant = 'primary' | 'secondary' | 'error'
type ButtonSubVariant = 'normal' | 'prominent'

type ButtonStyleParams = {
  variant: ButtonVariant
  subvariant?: ButtonSubVariant
  nowrap?: boolean
}

type ButtonParams = ButtonStyleParams & { text: string; url: string }

const getFontWeightClassName = ({
  variant,
  layout,
}: {
  variant: ButtonVariant
  layout: ContentfulLayout
}) => {
  const { primaryButtonFontWeight, secondaryButtonFontWeight } = layout

  const buttonWeights = {
    primary: primaryButtonFontWeight,
    secondary: secondaryButtonFontWeight,
    error: 'error',
  }

  return {
    normal: styles.fwNormal,
    bold: styles.fwBold,
  }[buttonWeights[variant]]
}

const getStyleClassName = ({
  variant,
  layout,
}: {
  variant: ButtonVariant
  layout: ContentfulLayout
}) => {
  const { primaryButtonStyle, secondaryButtonStyle } = layout

  const buttonStyles = {
    primary: primaryButtonStyle,
    secondary: secondaryButtonStyle,
    error: 'error',
  }

  return {
    solid: styles.styleSolid,
    outline: styles.styleOutline,
    error: styles.styleError,
  }[buttonStyles[variant]]
}

const getSubVariantClassName = (subvariant: ButtonSubVariant) => {
  return {
    normal: styles.subvariantNormal,
    prominent: styles.subvariantProminent,
  }[subvariant]
}

export const useButtonStyles = () => {
  const getButtonClassNames = ({
    variant,
    subvariant,
    nowrap,
  }: ButtonStyleParams) => {
    const layout = useLayoutContext()
    return [
      styles.button,
      getFontWeightClassName({ variant, layout }),
      getStyleClassName({ variant, layout }),
      getSubVariantClassName(subvariant || 'normal'),
      nowrap ? styles.nowrap : undefined,
    ].join(' ')
  }
  return { getButtonClassNames }
}

export const Button = ({ text, url, ...styleParams }: ButtonParams) => {
  const { getButtonClassNames } = useButtonStyles()
  return (
    <a href={url} className={getButtonClassNames(styleParams)}>
      {text}
    </a>
  )
}
