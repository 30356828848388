import React from 'react'
import { graphql } from 'gatsby'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentfulPageDirectory } from 'types/contentful'
import { Button } from './button'
import RichText from './rich-text'
import * as styles from './directory-banner.module.css'

type Props = {
  contentfulPageDirectory: ContentfulPageDirectory
}

const DirectoryBanner = ({ contentfulPageDirectory }: Props) => {
  const {
    bannerBackgroundImage,
    bannerHeading,
    bannerContent,
    bannerButtonOneText,
    bannerButtonOneUrl,
    bannerButtonTwoText,
    bannerButtonTwoUrl,
  } = contentfulPageDirectory

  const bgImage = bannerBackgroundImage
    ? convertToBgImage(bannerBackgroundImage.gatsbyImageData)
    : null

  return (
    <div className="edge-container">
      {bannerBackgroundImage && (
        <GatsbyImage
          className={styles.mobileBannerImage}
          alt={bannerBackgroundImage.title || 'Banner'}
          image={bannerBackgroundImage.gatsbyImageData}
          objectFit="cover"
          objectPosition="100% 0%"
        />
      )}
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <div className={styles.leftSide}>
          <div className={styles.bubble}>
            <h1 className={styles.heading}>{bannerHeading}</h1>
            {bannerContent && <RichText data={bannerContent} />}
          </div>
          {bannerButtonOneUrl && (
            <div className={styles.buttonWrapper}>
              <Button
                text={bannerButtonOneText}
                url={bannerButtonOneUrl}
                variant="primary"
              />
            </div>
          )}
          {bannerButtonTwoUrl && (
            <div className={styles.buttonWrapper}>
              <Button
                text={bannerButtonTwoText}
                url={bannerButtonTwoUrl}
                variant="secondary"
              />
            </div>
          )}
        </div>
      </BackgroundImage>
    </div>
  )
}

export default DirectoryBanner

export const query = graphql`
  fragment DirectoryBannerFields on ContentfulPageDirectory {
    bannerBackgroundImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
    }
    bannerHeading
    bannerContent {
      raw
    }
    bannerButtonOneText
    bannerButtonOneUrl
    bannerButtonTwoText
    bannerButtonTwoUrl
  }
`
