import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentfulSectionText } from 'types/contentful'
import RichText from './rich-text'
import * as styles from './section-text-boxes.module.css'

type SectionTextBoxesProp = {
  heading?: string
  textBoxes: ContentfulSectionText[]
  textColorHex?: string
  backgroundColorHex?: string
  index: number
}

const SectionTextBoxes = ({
  heading,
  textBoxes,
  textColorHex,
  backgroundColorHex,
}: SectionTextBoxesProp) => {
  return (
    <div className="edge-container">
      <div
        className={styles.sectionTextBoxes}
        style={{ backgroundColor: backgroundColorHex }}
      >
        {heading && (
          <h2 className={styles.heading} style={{ color: textColorHex }}>
            {heading}
          </h2>
        )}
        <div className={styles.boxes}>
          {textBoxes.map((textBox) => (
            <div key={textBox.id} className={styles.box}>
              {textBox.image && (
                <GatsbyImage
                  alt={textBox.image.title || 'Image'}
                  image={textBox.image.gatsbyImageData}
                />
              )}
              <h3 className={styles.boxHeading}>{textBox.heading}</h3>
              {textBox.text && <RichText data={textBox.text} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SectionTextBoxes

export const query = graphql`
  fragment ContentfulSectionTextBoxesFields on ContentfulSectionTextBoxes {
    id
    heading
    textBoxes {
      id
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      heading
      text {
        raw
      }
    }
    textColorHex
    backgroundColorHex
  }
`
